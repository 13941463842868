.chatbot-wrapper {
  padding: 60px 0;
  margin-top: 20px;
}

.chatbot-container {
  padding: 0 60px;
}



iframe {
  border: none;
  display: block;
  margin: auto;
  max-width: 800px;
  min-height: 780px;
  width: 100%;
}

.chatbot-header{
max-width: 750px;
margin: auto;
    position: relative;
    margin-bottom: -20px;
    z-index: 20;
}