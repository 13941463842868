

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  max-width: 1050px;
  margin: 20px auto;
  padding-top: 40px;
  padding: 60px 40px 20px 40px;
}

.textContainer h2{
    font-size: 42px;
    margin-bottom: 10px;
}

.textContainer h2,
.textContainer h3{
    color: #343434;
}

.textContainer h3{
    font-size: 24px;
}

.textContainer {
  flex: 1;
  margin-right: 20px;
}

.textContainer ul{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 18px;
    margin-left: 20px;
    list-style-type: disc;
}

.textContainer li{
list-style: disc;

font-size: 16px !important ;
color: #535353;
line-height: 1.6;
font-family: 'futura-pt-light';
}

.heading {
  font-size: 24px;
  margin-bottom: 5px;
}

.imageContainer {
  flex: 1;
  
}

.image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}


@media (max-width: 767px) {
    .container{
        display: flex;
        flex-direction: column-reverse;
        padding: 60px 20px 20px 20px;
    }
}